import React from "react";
import { css } from "goober";

import useChampionsARAM from "@/game-lol/utils/useChampionsARAM.jsx";
import useChampionsFilter from "@/game-lol/utils/useChampionsFilter.jsx";
import DataTable from "@/shared/DataTable.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

const ChampionTable = () => css`
  .champion-meta {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  .champion-name {
    width: 12ch;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

function StatisticsARAM() {
  const { searchParams } = useRoute();
  const { FilterBar, ...filterParams } = useChampionsFilter({
    tab: "aram",
  });

  const { rows, cols } = useChampionsARAM(searchParams);

  return (
    <>
      {FilterBar}
      <DataTable
        cols={cols}
        rows={rows}
        indexCol
        className={ChampionTable()}
        sortCol={1} // Tier
        sortColTiebreak={2} // Win Rate
        sortDir="DESC"
        searchText={filterParams.searchText}
        searchCol={0}
      />
    </>
  );
}

export default StatisticsARAM;
