import React from "react";
import { css } from "goober";

import useChampionsArenaDuos from "@/game-lol/utils/useChampionsArenaDuos.jsx";
import useChampionsFilter from "@/game-lol/utils/useChampionsFilter.jsx";
import DataTable from "@/shared/DataTable.jsx";

const ChampionTable = () => css`
  .champion-meta {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  .champion-name {
    width: 12ch;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .champion-duos {
    display: flex;
    gap: 0.25rem;
  }
`;

function StatisticsArena() {
  const { FilterBar, ...filterParams } = useChampionsFilter({
    tab: "arena",
  });

  const { rows, cols } = useChampionsArenaDuos();

  return (
    <>
      {FilterBar}
      <DataTable
        cols={cols}
        rows={rows}
        indexCol
        className={ChampionTable()}
        sortCol={1} // Tier
        sortColTiebreak={2} // Avg place
        sortDir="DESC"
        searchText={filterParams.searchText}
        searchCol={0}
      />
    </>
  );
}

export default StatisticsArena;
