import React from "react";
import { css } from "goober";

import useChampionsFilter from "@/game-lol/utils/useChampionsFilter.jsx";
import useChampionsSynergies from "@/game-lol/utils/useChampionsSynergies.jsx";
import DataTable from "@/shared/DataTable.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

const ChampionTable = () => css`
  .champion-meta {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  .champion-name {
    width: 12ch;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .champion-matchups {
    display: flex;
    gap: 0.25rem;
  }
`;

function StatisticsSynergies() {
  const { searchParams } = useRoute();
  const { FilterBar, ...filterParams } = useChampionsFilter({
    tab: "synergies",
  });

  const { rows, cols } = useChampionsSynergies(searchParams);

  return (
    <>
      {FilterBar}
      <DataTable
        cols={cols}
        rows={rows}
        indexCol
        className={ChampionTable()}
        sortCol={2} // Tier
        sortColTiebreak={3} // Win Rate
        sortDir="DESC"
        searchText={filterParams.searchText}
        searchCol={1}
      />
    </>
  );
}

export default StatisticsSynergies;
