import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import {
  ROLE_SYMBOL_TO_STR,
  ROLE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon-path.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import {
  getDefaultedFiltersForChampions,
  getSearchParamsForChampions,
  getStaticData,
} from "@/game-lol/utils/util.mjs";
import getTierIconPath from "@/shared/get-tier-icon-path.mjs";
import { calcRate } from "@/util/helpers.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const percentOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
};

const commonColumnCss = `
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--shade1);
  font-size: var(--sp-3_5);
`;

// TODO (Rio): ZzZzZzZz these need to be removed
export const cssRank = () => css`
  color: var(--shade0);
`;
export const cssChampionName = () => css`
  ${commonColumnCss};
  justify-content: flex-start;
  color: var(--shade0);
  padding-inline: var(--sp-2);

  .championKey {
    display: flex;
    align-items: center;
    gap: var(--sp-4);

    ${mobile} {
      gap: var(--sp-3);
    }
  }

  span {
    color: var(--shade0);
  }
`;

const useChampionsOverview = (searchParams, filterRole) => {
  const filters = getDefaultedFiltersForChampions(searchParams, "overview");
  const urlParams = getSearchParamsForChampions(false, filters);

  const filterRoleSymbol = RoleSymbol(filterRole);

  const { t } = useTranslation();
  const {
    lol: { championStats },
  } = useSnapshot(readState);

  const stats = championStats?.[urlParams.toString()];

  const champions = getStaticData("champions");

  const rows = useMemo(() => {
    if (!champions || !stats || stats instanceof Error) return null;

    return stats.map((champStats) => {
      const { championId, role, banRate, pickRate, games, tierListTier } =
        champStats;

      const championKey = champions?.keys?.[championId];
      const champion = champions?.[championKey];

      if (!champion || !championKey) return null;

      const linkParams = new URLSearchParams(searchParams);
      linkParams.set("role", ROLE_SYMBOL_TO_STR[role]?.gql || role);

      const championName = champion?.name;
      const RoleIcon = role && getRoleIcon(role);
      const tier = tierListTier?.tierRank || 5;
      const TierIcon = getTierIconPath(tier);
      const wr = calcRate(champStats.wins, champStats.games);

      const matchups = [];

      // Note: matchups array is already ordered by games.
      // let's just get the most common matchups with some winrate threshold.
      const WIN_RATE_THRESHOLD = 0.4;
      const MAX_MATCHUPS = 4;
      for (const matchup of champStats.matchups || []) {
        const { laneWins, games } = matchup;
        if (laneWins / games < WIN_RATE_THRESHOLD) {
          matchups.push(matchup);
          if (matchups.length >= MAX_MATCHUPS) break;
        }
      }

      return [
        {
          display: RoleIcon ? (
            <img src={RoleIcon} width="24" height="24" />
          ) : null,
          value: role,
        },
        {
          display: (
            <>
              <ChampionImg id={championId} size={28} />
              <span className="name-string champion-name">{championName}</span>
            </>
          ),
          value: championName,
          className: "champion-meta",
          link: `/${lolRefs.lolChampionPrefix}/${championKey}/build?${linkParams}`,
        },
        {
          display: <img src={TierIcon} width="28" height="28" />,
          value: tier * -1,
        },
        {
          display: getLocaleString(wr, percentOptions),
          value: wr,
          // style: { color: getWinRateColor(wr * 100) },
        },
        { display: getLocaleString(banRate, percentOptions), value: banRate },
        { display: getLocaleString(pickRate, percentOptions), value: pickRate },
        { display: getLocaleString(games), value: games },
        {
          display: (
            <div className="champion-matchups">
              {matchups.map((matchup, i) => (
                <ChampionImg
                  key={i}
                  id={matchup.opponentChampionId}
                  size={20}
                />
              ))}
            </div>
          ),
          value: null,
        },
      ];
    });
  }, [stats, champions, searchParams]);

  const filteredData = useMemo(() => {
    if (!rows) return null;
    return rows
      .filter((v) => {
        if (!filterRoleSymbol || filterRoleSymbol === ROLE_SYMBOLS.all)
          return true;
        const champRoleSymbol = v[0].value;
        return champRoleSymbol === filterRoleSymbol;
      })
      .sort((a, b) => {
        // Default Sort: tier, tie-break with winrate
        return b?.[2]?.value - a?.[2]?.value || b?.[3]?.value - a?.[3]?.value;
      });
  }, [rows, filterRoleSymbol]);

  return {
    cols: [
      { display: t("lol:role", "Role"), align: "center" },
      { display: t("lol:champion", "Champion"), align: "left", primary: true },
      { display: t("common:tier", "Tier"), align: "center" },
      {
        display: t("common:winRate", "Win Rate"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:banRate", "Ban Rate"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:pickRate", "Pick Rate"),
        align: "right",
        isStat: true,
      },
      { display: t("common:matches", "Matches"), align: "right", isStat: true },
      { display: t("common:counteredBy", "Countered By"), align: "right" },
    ],
    rows: filteredData,
  };
};

export default useChampionsOverview;
